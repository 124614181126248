<template>
	<div class="facebook-source">
		
		<div class="stepper-md">
			<div class="mdl-card mdl-shadow--2dp">
				<div class="mdl-card__supporting-text">
					<div class="mdl-stepper-horizontal-alternative">
						<div v-for="(item, key) in tabsPanel"
							:class="['mdl-stepper-step', item.selected ? 'active-step' : '', item.finished ? 'step-done' : '' ]" :key="`tab-${key}`">
							<div class="mdl-stepper-circle"><span>{{ item.id }}</span></div>
							<div class="mdl-stepper-title"> {{ $t(item.name) }} </div>
							<div class="mdl-stepper-bar-left"></div>
							<div class="mdl-stepper-bar-right"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="row" v-show="tabsPanel[0].selected && !tabsPanel[0].finished" :key="`A-${1}`">
				
				<div class="col-md-7 mx-auto">
					<h3>{{ $t('ConnectFbTitle') }}</h3>
					<p>{{ $t('ConnectFbDesc') }}</p>
					
					<center>
						<v-facebook-login 
							app-id="155127881962747" 
							:loginOptions="{ scope: 'pages_show_list,ads_management,business_management,leads_retrieval,pages_read_engagement,pages_manage_metadata,pages_read_user_content,pages_manage_ads,public_profile,email', return_scopes : true }" 
							@sdk-init="sdkLoaded" 
							@login="onLogin" 
							@logout="onLogout">
						</v-facebook-login>
						<!-- business_management, manage_pages, publish_pages, publish_actions, public_profile, ads_management -->
					</center>
					
					<!--facebook-login class="button" appId="155127881962747" @login="onLogin" @logout="onLogout" @get-initial-status="getUserData" @sdk-loaded="sdkLoaded"></facebook-login-->
					
					<div class="row" v-if="isConnected">
						<div class="col-md-12 text-right">
							<b-button variant="success" size="sm" @click="stepNext(2)">{{ $t('Next') }} <i class="fa fa-chevron-right"></i> </b-button>
						</div>
					</div>
					
				</div>
			</div>
			<div class="row" v-show="tabsPanel[1].selected && !tabsPanel[1].finished" :key="`A-${2}`">
				<div class="col-md-6 mx-auto">
					
					<h3>{{ $t('SelectLeadTitle') }}</h3>
					<p>{{ $t('SelectLeadDesc') }}</p>
					
					<div class="form-group mb-3">
						<!--label for="facebook_pages">
							<span v-if="loadingPage">Please wait... <i class="fa fa-spinner fa-spin"></i></span>
							<span v-else>Select your facebook page</span>
						</label-->
						<!--select class="form-control" v-model="selected_page" @change="pageSelected">
							<option v-for="(item, key) in pages" v-bind:key="`page-${key}`" :value="key" v-text="item.name"></option>
						</select-->
						
						<multi-select 
							:options="myPages" 
							:selected="selectedPages" 
							label="Select your facebook page" 
							placeholderType="title" 
							:placeholder="$t('Page')" 
							@filterChange="pageSelected" :caret="true"></multi-select>
							
						<div v-if="loadingPage">{{ $t('PleaseWait') }} <i class="fa fa-spinner fa-spin"></i></div>
						<div class="help-block">
							{{ messagePage }}
						</div>
					</div>
					
					<div class="form-group" v-if="messagePage == '' && forms.length > 0">
						<!--label for="facebook_page_forms">
							<span>Select your facebook lead ad form</span>
						</label-->
						<!--select class="form-control" name="facebook_page_form" v-model="facebook_page_form" id="facebook_page_forms" title="Facebook page lead ad forms" @change="formSelected">
							<option v-for="(form, key) in forms" :value="key" v-bind:key="`form-${key}`">
								{{ form.name }}
							</option>
						</select-->
						
						<multi-select 
							:options="myForms" 
							:selected="selectedForms" 
							:label="$t('SelectYourFacebookLeadAdForm')" 
							placeholderType="title" 
							placeholder="Form" 
							@filterChange="formSelected" :caret="true"></multi-select>
						
						<div class="help-block">
							{{ messagePageLeads }}
						</div>
					</div>
					
					<div class="row">
						<div class="col-md-6">
							<b-button size="sm" @click="stepPrevious(0)"><i class="fa fa-chevron-left"></i> {{ $t('Previous') }}</b-button>
						</div>
						<div class="col-md-6 text-right" v-if="messagePage == '' && nameSource != '' && messagePageLeads == ''">
							<b-button variant="success" size="sm" @click="stepNext(3)">{{ $t('Next') }} <i class="fa fa-chevron-right"></i> </b-button>
						</div>
					</div>
					
				</div>
			</div>
			<div class="row" v-show="tabsPanel[2].selected && !tabsPanel[2].finished" :key="`A-${3}`">
				<div class="col-md-8 mx-auto">
					<div class="collapse-md">
						
						<div :class="['form-group', validate ? 'has-error' : '']">
							<label for="nameSource">{{ $t('NameYourSource') }}</label>
							<input type="text" class="form-control" id="nameSource" :placeholder="$t('LeadSourceName')" v-model="nameSource" />
							<small v-if="validate" class="help-block">{{ $t('NameSourceTitle') }}</small>
						</div>
						
						<b-card no-body class="mb-1">
							<b-card-header header-tag="header" class="p-1" role="tab">
								<b-button block v-b-toggle.accordion-1 variant="light" :class="['text-left', visibleCollapse[0] ? 'text-success' : '']">
									<i class="fa fa-random"></i> {{ $t('FieldsMapping') }}
								</b-button>
							</b-card-header>
							<b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel" v-model="visibleCollapse[0]">
								<b-card-body class="border-top">
									<b-card-text>
										
										<fields-mapping 
										:questionsFields="questionsFields"
										:fields="fields"
										:mapping_fields="mapping_fields"
										:fieldsLabel="fieldsLabel" @updateFieldValue="updateFieldValue">
										</fields-mapping>
										
										<div class="form-group row">
											<div class="col-12">
												<div class="checkbox">
													<label for="admin">
														<input id="admin" type="checkbox" v-model="import_old_leads">
														<span class="cr"><b class="cr-icon fa fa-check"></b></span><span> {{ $t('ImportExistingLeads') }} {{ existingLeads }}</span>
													</label>
												</div>
											</div>
										</div>
										
									</b-card-text>
								</b-card-body>
							</b-collapse>
						</b-card>
						
						<b-card no-body class="mb-1">
							<b-card-header header-tag="header" class="p-1 d-flex" role="tab">
								<b-button block v-b-toggle.accordion-2 variant="light" :class="['text-left', visibleCollapse[1] ? 'text-success' : '']">
									<i class="fa fa-bell-o"></i> {{ $t('LeadAlert') }}
								</b-button>
								<b-form-checkbox v-model="notificationArray.active" name="check-button" switch size="lg" class="mt-1"></b-form-checkbox>
							</b-card-header>
							<b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" v-model="visibleCollapse[1]">
								<b-card-body class="border-top">
									<b-card-text>
										<lead-alert-tabs :notificationArray="notificationArray"></lead-alert-tabs>
									</b-card-text>
								</b-card-body>
							</b-collapse>
						</b-card>
						
						<b-card no-body class="mb-1">
							<b-card-header header-tag="header" class="p-1 d-flex" role="tab">
								<b-button block v-b-toggle.accordion-3 variant="light" :class="['text-left', visibleCollapse[2] ? 'text-success' : '']">
									<i class="fa fa-envelope-o "></i> {{ $t('WelcomeEmail') }}
								</b-button>
								<b-form-checkbox v-model="confirmationArray.active" name="check-button" switch size="lg" class="mt-1"></b-form-checkbox>
							</b-card-header>
							<b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel" v-model="visibleCollapse[2]">
								<b-card-body class="border-top">
									<b-card-text>
										<welcome-email-tabs :confirmationArray="confirmationArray"></welcome-email-tabs>
									</b-card-text>
								</b-card-body>
							</b-collapse>
						</b-card>
						
						<div class="row pt-2 pb-5">
							<div class="col-md-6">
								<b-button class="mr-2" @click="stepPrevious(1)"><i class="fa fa-chevron-left"></i> {{ $t('Previous') }}</b-button>
							</div>
							<div class="col-md-6 text-right">
								<b-button variant="success" @click="saveSource">
									<i :class="['fa', loadingProc ? 'fa-spinner fa-spin' : 'fa-cogs']"></i> {{ $t('Finish') }}
								</b-button>
							</div>
						</div>
						
					</div>
					
				</div>
			</div>
		</div>
		<br><br><br><br>
		
	</div>
</template>
<script>
	
	import findIndex from 'lodash/findIndex'
	import each from 'lodash/each'
	import leadAlertTabs from '@/components/sections/LeadAlertTabs.vue'
	import welcomeEmailTabs from '@/components/sections/WelcomeEmailTabs.vue'
	
	import FieldsMapping from '@/components/sections/FieldsMapping.vue'
	
	import { VFBLogin as VFacebookLogin } from 'vue-facebook-login-component'
	
	// import axios from 'axios'
	import multiSelect from '@/plugins/md/multiselect/select-dropdown.vue';
	export default {
		name		: 'facebookLeadAds',
		components	: {
			VFacebookLogin,
			leadAlertTabs,
			welcomeEmailTabs,
			FieldsMapping,
			multiSelect
		},
		data() {
			return {
				
				myPages			: [],
				selectedPages	: [],
				
				myForms			: [],
				selectedForms	: [],
				
				notificationArray: {
					active		: true,
					type 		: 'notification',
					subject		: '',
					from_email	: '',
					from_name	: '',
					recipients	: [],
					content		: '',
				},
				confirmationArray: {
					active		: false,
					type		: 'confirmation',
					from_email	: '',
					from_name	: '',
					subject		: '',
					content		: '',
					
					sender_email: '',
					sender_name : '',
				},
				
				visibleCollapse	: [ true, false, false ],
				nameSource		: '',
				tabsPanel		: [
					{ id:1, name:'Connect', selected: true,  finished: false },
					{ id:2, name:'Select Form', selected: false, finished: false },
					{ id:3, name:'Settings', selected: false, finished: false },
				],
				
				isConnected	 : false,
				// name		 : '',
				// email	 : '',
				// personalID: '',
				picture		 : '',
				FB			 : undefined,
				pages		 : [],
				
				accessToken  : '',
				selected_page: '',
				
				fields		: {},
				fieldsLabel	: {},
				forms		:	[],
				forms_loaded:	false,
				canContinue	:	false,
				is_more		:	false,
				
				messagePage: '',
				messagePageLeads: '',
				
				facebook_page_form: '',
				checking_source_existence: false,
				
				logged_in: false,
                loadingPage: false,
                loadingProc: false,
                validate: false,
                
                user: {},
                
                import_old_leads: true,
                
                step: 'step-1',
                
				mapping_fields : [
					{ 'label' : 'Full name', 		'key' : 'full_name', 			'selected' : false },
					{ 'label' : 'First name', 		'key' : 'personal_first_name', 	'selected' : false },
					{ 'label' : 'Last Name', 		'key' : 'personal_last_name', 	'selected' : false },
					{ 'label' : 'Email', 			'key' : 'email', 				'selected' : false },
					{ 'label' : 'Phone number', 	'key' : 'personal_phone', 		'selected' : false },
					{ 'label' : 'Created On', 		'key' : 'added_at', 			'selected' : false },
					{ 'label' : 'Custom field', 	'key' : 'custom_field', 		'selected' : false },
				],
				
				questionsFields: []
			}
		},
		methods: {
			
			notifyMe(message, variant){
				this.$notify({
					group	: 'foo',
					// title: 'Important message',
					text	: message,
					type	: variant,
					// duration: 5000,
					// speed	: 1000
				});
				
			},
			
			saveSource() {
				
                const that = this
				that.validate = false
				
				let dataSave = {
					userFBid		: '',
					sourceName		: that.nameSource,
					import_old_leads: that.import_old_leads,
                    form			: that.forms[ that.facebook_page_form ],
                    page			: that.pages[ that.selected_page ],
                    fields			: that.fields,
                    fieldsLabels	: that.fieldsLabel,
                    user			: that.user,
                    notification	: that.notificationArray,
                    confirmation	: that.confirmationArray,
					provider 		: "add sources"
                };
				dataSave.userFBid = that.user.id
				that.loadingProc = true
				if( that.nameSource != '' ) {
					
					let url		= 'save_facebook_source'
					let method	= 'POST'
					let data	= dataSave
					
					that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
						if(response.data.success) {
							that.loadingProc = false
							let source_id = response.data.source_id
							that.notifyMe('<div id="source-added-facebook">' + this.ChangesSaved + '</div>', 'success')
							
							setTimeout( function() {
								if( source_id != null && typeof( source_id ) != 'undefined' ) {
									// that.$router.push({ path: "/myleads/" + source_id })
									that.$router.push({path: '/myleads/' + source_id, params: {source: source_id}})
								} else {
									that.$router.push({ name: "myleads" })
								}
							}, 1000 )
							
							that.leftSidebarInfo()
						} else {
							that.loadingProc = false
							that.notifyMe(response.data.message, 'warn')
						}	
					}).catch(( {response} ) => {
						that.loadingProc = false
						that.notifyMe(response.data.message, 'warn')
					})
					
				} else {
					that.validate = true
					that.notifyMe(this.$t('PleasProvideNameSource'), 'warn')
					that.loadingProc = false
				}
            },
			
			stepPrevious(element) {
				
				this.tabsPanel.forEach( function( el, key ) {
					if( element < key ) {
						el.selected = false
						el.finished = false
					}
				} );
				
				this.tabsPanel[element].selected = true;
				this.tabsPanel[element].finished = false;
				
			},
			
			stepNext(element) {
				
				this.tabsPanel.forEach( function( el ) {
					
					if( element > el.id ) {
						el.selected = true
						el.finished = true
					} else if( element == el.id ) {
						el.selected = true;
						el.finished = false;
					} else {
						el.selected = false
						el.finished = false
					}
					
				} );
				
			},
			
			updateFieldValue(key, value, label) {
                let that = this
				this.$set(that.fields, key, value);
				this.$set(that.fieldsLabel, key, label);
                
                that.resetSelectedFields();
				
				each(that.fields, (field) => {
					
					let fieldIndex = findIndex(that.mapping_fields, {key: field});
					
					if( typeof( that.mapping_fields[fieldIndex] ) != 'undefined' ) {
						that.mapping_fields[fieldIndex].selected = true;
					}
					
                })
            },
            
            resetSelectedFields() {
                each(this.mapping_fields, (field) => {
                    field.selected = false
                });
            },
			
			formSelectedGetLeads() {
				
				let that = this
				let pageId = that.pages[ that.selected_page ].id
				let formId = that.forms[ that.facebook_page_form ].id
				let page_access_token = that.pages[ that.selected_page ].access_token
				console.log( pageId )
				console.log( formId )
				// console.log( page_access_token )
				// let legend_form_url = '/' + pageId + '/subscribed_apps';
				let legend_form_url = '/' + formId + '/leads';
				// let legend_form_url = '/' + formId + '/leads?fields=created_time,id,ad_id,form_id,field_data';
				that.FB.api(legend_form_url, 'GET', { access_token: page_access_token }, (response) => {
					
					console.log( response )
					
				});
				// let legend_form_url = '/' + that.pages[ that.selected_page ].id + '/leadgen_forms?fields=id,name,created_time,leads_count,expired_leads_count,status,questions&limit=15';
				// const fbForms = await this.getFBForms(legend_form_url,that.pages[ that.selected_page ].access_token);
				
				// POst Method
				// -F 'subscribed_fields="leadgen"' \
				// -F 'access_token=<ACCESS_TOKEN>' \
				// https://graph.facebook.com/v6.0/{page-id}/subscribed_apps
				
				/* this.leadAdsRetrieval() */
				
			},
			
			async leadAdsRetrieval() {
				let that = this
				let legend_form_url = '/' + that.forms[ that.facebook_page_form ].id + '/leads';
				
                do {
                    const fbFormsAds = await that.getleadAdsRetrieval(legend_form_url,that.pages[ that.selected_page ].access_token);
                    if(fbFormsAds != false){
                        
                        if (typeof fbFormsAds.paging.next === 'undefined') {
                            that.is_more = false;
                        } else {
                            that.is_more = true;
                            legend_form_url = fbFormsAds.paging.next;
                        }
                    }else{
                        break;
                    }

                }while (that.is_more);
				
            },
			
			getleadAdsRetrieval(legend_form_url,access_token) {
				let that = this
                if ( typeof legend_form_url === 'undefined' ) {
                    return false;
                } else {
                    return new Promise(async (resolve) => {
                        await that.FB.api(legend_form_url, 'GET', { access_token: access_token }, 
						(response) => {
                            var resultArray = ''
							if( typeof response.data != 'undefined' ) {
								resultArray = response.data.join('')
							}
							
							if (resultArray == '' || resultArray.length === 0 ) {
								resolve(false);
								return false;
							}else{
								resolve(response);
							}
							
						});
                    });
                }
			},
			
			loadDefaultLeadAlert() {
				
				let that = this
				
				let url   = 'lead_alert'
				let method= 'GET'
				let data  = {}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					// that.notificationArray = response.data;
					that.notificationArray.active		= response.data.active
					that.notificationArray.type 		= response.data.type
					that.notificationArray.subject		= response.data.subject
					that.notificationArray.from_email	= response.data.from_email
					that.notificationArray.from_name	= response.data.from_name
					that.notificationArray.recipients	= response.data.recipients
					that.notificationArray.content		= response.data.content
					
				}).catch((  ) => {
				})
				
            },
			
			loadDefaultWelcomeEmail() {
                
				let that = this
				
				let url   = 'welcome_email'
				let method= 'GET'
				let data  = {}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					
					// that.confirmationArray = response.data;
					let content = response.data.content.toString()
					that.confirmationArray.active		= response.data.active
					that.confirmationArray.type 		= response.data.type
					that.confirmationArray.subject		= response.data.subject
					that.confirmationArray.from_email	= response.data.from_email
					that.confirmationArray.from_name	= response.data.from_name
					that.confirmationArray.content		= content
					
					that.confirmationArray.sender_email	= response.data.sender_email
					that.confirmationArray.sender_name	= response.data.sender_name
					that.loading	  = false;
					
				}).catch((  ) => {
				})
				
            },
			
			formSelected() {
				let that = this
				that.nameSource = '';
                
				that.facebook_page_form = that.selectedForms[0]
				
				that.resetSelectedFields();
                that.fields = {};
                that.fieldsLabel = {};
                that.checking_source_existence = true;
                that.forms_loaded = false;
				that.messagePageLeads = ''
				that.questionsFields = that.forms[that.facebook_page_form].questions
				
				let url		= 'check_existence_source/' + that.forms[ that.facebook_page_form ].id
				let method	= 'GET'
				let data	= {}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					
					if (response.data.success) {
						that.messagePageLeads = response.data.message
						that.checking_source_existence = false
					} else {
						that.forms_loaded = true
						that.canContinue  = true
						that.autoMapping()
						that.loadDefaultLeadAlert()
						that.loadDefaultWelcomeEmail()
					}
					that.nameSource = that.forms[ that.facebook_page_form ].name
					
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.loading = false
				})
				
            },
			
			autoMapping() {
                let that = this;
				let fieldArray = {
					'first_name'  :'personal_first_name',
					'last_name'	  :'personal_last_name',
					'full_name'	  :'full_name',
					'email'		  :'email',
					'phone_number':'personal_phone'
					// 'numéro_de_téléphone':'personal_phone'
				};
				if( typeof that.forms[ that.facebook_page_form ] !== "undefined" ) {
					each( that.forms[ that.facebook_page_form ].questions, (qualifier, key) => {
						
						let field = qualifier.key.toLowerCase()
						let type  = qualifier.type.toLowerCase()
						
						if( typeof fieldArray[ field ] != "undefined" || typeof fieldArray[ type ] != "undefined" ) {
							field = typeof fieldArray[ field ] != "undefined" ? fieldArray[ field ] : fieldArray[ type ]
							that.updateFieldValue( qualifier.key, field, qualifier.label );
							qualifier.mapped = true;
						} else if( field.match(/phone/) && !that.mapping_fields[4].selected ) {
							that.updateFieldValue( qualifier.key, fieldArray[ 'phone_number' ], qualifier.label );
							qualifier.mapped = true;
						} else {
							that.updateFieldValue(qualifier.key, 'custom_field', qualifier.label );
						}
						
						// key	: "last_name"
						// label: "Last name"
						// type	: "LAST_NAME"
						if (key == (that.forms[ that.facebook_page_form ].questions.length - 1)) {
							that.updateFieldValue("campaign_name", 'custom_field', "Campaign Name" );
							that.updateFieldValue("ad_name", 'custom_field', "Ad Name" );
							that.updateFieldValue("adset_name", 'custom_field', "Adset Name" );
						}
						
					});
				}
            },
			
			async pageSelected() {
				const that = this
                that.resetSelectedFields();
				that.messagePage = ""
                that.fields = {};
                that.forms = [];
				
				let myForms			= []
				that.myForms		= []
				that.selectedForms	= []
				
				
                that.forms_loaded = false;
                that.canContinue = false;
                that.is_more = false;
				
				that.selected_page = that.selectedPages[0]
				
				let legend_form_url = '/' + that.pages[ that.selected_page ].id + '/leadgen_forms?fields=id,name,created_time,leads_count,expired_leads_count,status,questions&limit=15';
				
                do {
                    const fbForms = await this.getFBForms(legend_form_url,that.pages[ that.selected_page ].access_token);
                    if(fbForms != false){
                        this.forms = this.forms.concat(fbForms.data);
						
						fbForms.data.forEach(function(item, id) {
							myForms.push({ id:id, title:item.name+' '+that.totalLeads(item) })
						})
						
                        if (typeof fbForms.paging.next === 'undefined') {
                            this.is_more = false;
                        } else {
                            this.is_more = true;
                            legend_form_url = fbForms.paging.next;
                        }
                    }else{
                        break;
                    }

                } while (this.is_more);
				
				
				myForms.forEach(function(item, id) {
					that.myForms.push({ id:id, title:item.title })
				})
				
            },
			
			getFBForms(legend_form_url,access_token) {
				
				let that = this
                if ( typeof legend_form_url === 'undefined' ) {
                    return false;
                } else {
                    return new Promise(async (resolve) => {
                        await that.FB.api(legend_form_url, 'GET',
                            { access_token: access_token }, (response) => {
                                
								var resultArray = ''
								if( typeof response.data != 'undefined' ) {
									resultArray = response.data.join('')
								}
								
								if (resultArray == '' || resultArray.length === 0 ) {
                                    this.messagePage = this.$t('PageNoLeadAd')
                                    
                                    resolve(false);
                                    return false;
                                }else{
                                    resolve(response);
                                }
                            });

                    });
                }

            },
			
			getUserData() {
				
				let that = this
				that.FB.login(
                    response => that.fbPostRequest(response),
                    {
                        scope				: 'pages_show_list,ads_management,business_management,leads_retrieval,pages_read_engagement,pages_manage_metadata,pages_read_user_content,pages_manage_ads,public_profile,email', // ,business_management
                        return_scopes		: true,
                        enable_profile_selector: true
					}
                )
			},
			
			fbPostRequest(result) {
				let that = this
				that.accessToken = result.accessToken;
				if ( result.status == 'connected' ) {
					// that.user = result.authResponse;
					that.getUserPages()
					
					that.tabsPanel[0].selected = true
					that.tabsPanel[0].finished = true
					
					that.tabsPanel[1].selected = true
					that.tabsPanel[1].finished = false
					
				} else {
					that.onLogout()
				}
			},
			
			getUserPages() {
				let that = this
				let params = {
					limit: 200,
					access_token: that.accessToken
				};
				that.loadingPage = true
				that.FB.api('me/accounts', 'GET', params, (response) => {
					that.pages = response.data;
					that.pages.forEach(function(item, key) {
						that.myPages.push({ id: key, title: item.name })
					})
					that.loadingPage = false
				})
				
				/* that.FB.api('/me', 'GET', 
					{ fields: 'id,name,email,picture' }, // ,manage_pages
					user => {
						that.user.personalID = user.id;
						that.user.email		 = user.email;
						that.user.name 		 = user.name;
						that.picture = user.picture.data.url;
					},
				) */
			},
			
			authFacebookUser(requestData) {
				let that = this
				
				let url   = 'facebook/login'
				let method= 'POST'
				let data  = requestData
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((/* response */) => {
					// console.log(response)
				}).catch(( /* {response} */ ) => {
					// console.log(response)
				})
				
			},
			
			handleSessionResponse(response) {
				let that = this
				
				if (response.status == "connected") {
					
					let requestData = response.authResponse
					
					let params = { limit: 200, access_token: response.authResponse.accessToken };
					that.FB.api('/me', 'GET', params, { fields: 'id,name' },
					(feedback) => {
						requestData.name = feedback.name
						that.authFacebookUser(requestData)
					})
					
					that.accessToken = response.authResponse.accessToken
					that.user = response.authResponse;
					//console.log( that.user )
					that.getUserPages()
					that.isConnected = true
					
					that.stepNext(2)
					
					
				} else {
					that.onLogout()
				}
				//if we dont have a session (which means the user has been logged out, redirect the user)
				
				//if we do have a non-null response.session, call FB.logout(),
				//the JS method will log the user out of Facebook and remove any authorization cookies
				
				// console.log( response )
				// FB.logout(handleSessionResponse);
			},
			
			sdkLoaded(payload) {
				let that = this
				that.FB = payload.FB
				
				let authResponse = JSON.parse( localStorage.getItem('fb-authResponse') )
				if( authResponse != null ) {
					localStorage.removeItem('fb-authResponse')
					let response = { status: "connected", authResponse: authResponse }
					that.handleSessionResponse(response)
				} else {
					that.FB.getLoginStatus(that.handleSessionResponse);
				}
				
				
				/* payload.FB.getLoginStatus( function( response ) {
					if( response.status == "connected" ) {
						that.FB.logout(function() {
							location.reload()
						})
					}
				} ) */
				
			},
			
			onLogin(response) {
				console.log(response)
				this.handleSessionResponse(response)
			},
			
			onLogout(resp = '') {
				console.log(resp)
				this.isConnected = false;
			},
			
			leftSidebarInfo() {
				let that = this
				let url   = 'left_sidebar'
				let method= 'GET'
				let data  = {}
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					console.log(response)
				}).catch(( {response} ) => {
					console.log(response)
				})
			},
			totalLeads(item) {
				let output  = '';
				if (typeof item !== "undefined") {
					let total = item.leads_count;
					switch (total) {
						case 0:
							output = this.$t('NoLeadSoFar');
							break;
						case 1:
							output = '( 1 lead )';
							break;
						default:
							output = `( ${total} Leads )`;
					}
				}
				return output;
			},
		},
		
		mounted() {
			let that = this
			
			setTimeout( function() {
				var fbauth = localStorage.getItem('fbauth')
				if( fbauth ) {
					that.notifyMe('<div id="connect-with-facebook-success">' + this.$t('Welcome') + '</div>', 'success')
					localStorage.removeItem('fbauth')
				}
			}, 1000 )
			
        },
		computed: {
			
			existingLeads() {
				let that 	= this
				let output  = '';
				
				if ( typeof that.forms[ that.facebook_page_form ] !== "undefined" ) {
					let total = that.forms[ that.facebook_page_form ].leads_count;
					switch (total) {
						case 0:
							output = '( No lead so far )';
							break;
						case 1:
							output = '1 lead';
							break;
						default:
							output = `( ${total} Leads )`;
					}
				}
				return output;
			},
			
		},
	}
</script>
<style lang="scss">
	
	.facebook-source {
		min-height: 75em;
		
		.active-step {
			.mdl-stepper-title {
				color: #2196f3 !important;
			}
		}
		
		.mdl-stepper-horizontal-alternative .mdl-stepper-step.active-step.step-done .mdl-stepper-title {
			color: #2196f3 !important;
		}
		
	}
	
	.stepper-md {
		
		.v-facebook-login {
			cursor: pointer;
			
			img.token {
				border-right: 1px solid #687db9;
				padding-right: 5px;
			}
			
		}
		
		.mdl-stepper-horizontal-alternative {
			display: table;
			width: 100%;
			margin: 0 auto;
		}
		
		.mdl-stepper-horizontal-alternative .mdl-stepper-step {
			display: table-cell;
			position: relative;
			padding: 24px;
		}
		
		.mdl-stepper-horizontal-alternative .mdl-stepper-step:last-child:active {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		
		.mdl-stepper-horizontal-alternative .mdl-stepper-step:first-child .mdl-stepper-bar-left,
		.mdl-stepper-horizontal-alternative .mdl-stepper-step:last-child .mdl-stepper-bar-right {
			display: none;
		}
		
		.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-circle {
			width: 24px;
			height: 24px;
			margin: 0 auto;
			background-color: #9E9E9E;
			border-radius: 50%;
			text-align: center;
			line-height: 2em;
			font-size: 12px;
			color: white;
		}
		
		.mdl-stepper-horizontal-alternative .mdl-stepper-step.active-step .mdl-stepper-circle {
			background-color: rgb(33, 150, 243);
		}
		
		.mdl-stepper-horizontal-alternative .mdl-stepper-step.step-done .mdl-stepper-circle:before {
			content: "\2714";
			
			/* content: "\f00c";
			font: normal normal normal 14px/1 FontAwesome; */
			
		}
		
		.mdl-stepper-horizontal-alternative .mdl-stepper-step.step-done .mdl-stepper-circle * {
			display: none;
		}
		
		.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-title {
			margin-top: 16px;
			font-size: 14px;
			font-weight: normal;
		}
		
		.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-title,
		.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-optional {
			text-align: center;
			color: #4e4e4e;
			font-weight: 600;
		}
		
		.mdl-stepper-horizontal-alternative .mdl-stepper-step.active-step.step-done .mdl-stepper-title {
			font-weight: 600;
			font-size: 14px;
		}
		
		.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-bar-left,
		.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-bar-right {
			position: absolute;
			top: 36px;
			height: 1px;
			border-top: 1px solid #BDBDBD;
		}
		
		.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-bar-right {
			right: 0;
			left: 50%;
			margin-left: 20px;
		}
		
		.mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-bar-left {
			left: 0;
			right: 50%;
			margin-right: 20px;
		}
	}
	
	
	.collapse-md {
		.card-header {
			background-color: #fff;
			border-bottom: 0;
			
			.btn-light {
				color: #2b2b2b;
				text-decoration: none;
				font-weight: 600;
				background-color: #fff;
				border: 0;
			}
			
			.btn-light:hover {
				color: #616161;
				background-color: #ffffff;
				border-color: #ffffff;
				font-weight: 500;
			}
		}
	}
	
	/* mapping */
	.btn {
        font-size: 14px;

        .fa-icon {
            margin-bottom: -5px;
        }
    }
	
    .fb-profile-picture {
        max-width: 80px;
    }
	
    .in {
        display: block;
    }
	
    select {
        min-height: 40px;
    }
	
    .label-icon {
        width: 16px;
        height: 16px;
        margin-right: 3px;
        display: inline;
    }
	
    select > option[disabled] {
        background: #efeeee;
    }
	
	
	
	
	
</style>
